<template>
  <div>
    <b-row>
      <b-col class="col-md-6 col-12">
        <Breadcrumbs main="" title="Service Mapping" class="showBreadcrumbs" />
        <!-- <Breadcrumbs main="" title="Service Mapping" :display="isBreadView" /> -->
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col xl="12" md="">
        <b-row class="align-items-start mt-2">
          <b-col class="col-md-3 col-12">
            <b-form-group label="Reason">
              <!--  :options="svalueData" -->
              <multiselect
                :options="originalProviderList"
                label="name"
                v-model="selectedReason"
                placeholder="Select Reason"
                :multiple="false"
                :close-on-select="true"
                track-by="_id"
              ></multiselect>
            </b-form-group>
          </b-col>

          <b-col class="col-md-3 col-12">
            <b-form-group label="Value">
              <!--  :options="svalueData" -->
              <multiselect
                :options="originalSvalueData"
                label="name"
                v-model="filterServiceMap.svalue"
                placeholder="Select value"
                :multiple="false"
                :close-on-select="true"
                track-by="_id"
              ></multiselect>
            </b-form-group>
          </b-col>

          <b-col class="col-md-3 col-12">
            <b-form-group label="Service">
              <!--  :options="serviceData"   -->
              <multiselect
                :options="originalServiceData"
                label="name"
                v-model="filterServiceMap.sid"
                placeholder="Select service"
                :multiple="false"
                :close-on-select="true"
                track-by="_id"
              ></multiselect>
            </b-form-group>
          </b-col>

          <b-col class="d-flex mt-4">
            <button
              @click="filterData"
              class="btn custom-btn"
              style="margin-top: 1vh"
            >
              Filter
            </button>
            <button
              @click="init()"
              class="ml-2 btn custom-btn"
              style="margin-top: 1vh"
            >
              Reset
            </button>
          </b-col>
        </b-row>

        <b-card title="" type="gradient-red" sub-title="" class="mb-4">
          <b-row class="align-items-start">
            <b-col class="col-md-6 col-12">
              <span class="custome-title">Service Mapping</span>
            </b-col>

            <b-col
              class="col-md-6 col-12 d-flex justify-content-end align-items-start"
            >
              <b-form-group
                label-cols="7"
                label="Per page"
                class="col-6 md-0 custom-font-18 text-right mr-2"
              >
                <b-form-select
                  class="border custom-font-24"
                  style="width: 70px;"
                  v-model="perPage"
                  :options="pageOptions"
                  @change="handlePerPageChange"
                >
                </b-form-select>
              </b-form-group>
              <button class="btn custom-btn" @click="OpenServiceMap()">
                Add Service Mapping
              </button>
            </b-col>
          </b-row>

          <b-row class="table-responsive datatable-vue">
            <b-table
              class="text-left custom-font-16"
              :fields="serviceMapperFields"
              striped
              hover
              :items="servicesMapData"
              :busy="isBusy"
              stacked="md"
              :filter="filter"
              :filterv="filterv"
              :filterReason="filterReason"
              
              :per-page="perPage"
              @filtered="onFiltered"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
            >
              <template #cell(sid)="row">
                <span
                  v-if="
                    serviceList.filter((item) => item.value == row.item.sid)
                      .length > 0
                  "
                >
                  {{
                    serviceList.filter((item) => item.value == row.item.sid)[0]
                      .text
                  }}
                </span>
              </template>
              <template #cell(reason)="row">
                <span
                  v-if="
                    reasonOptions.filter((t) => t.value == row.item.reason)
                      .length > 0
                  "
                >
                  {{
                    reasonOptions.filter((t) => t.value == row.item.reason)[0]
                      .text
                  }}
                </span>
              </template>
              <template #cell(action)="row">
                <button
                  size="sm"
                  @click="editServiceMap(row.item)"
                  class="btn text-blue btn-xs mr-1"
                >
                  <feather type="edit"></feather>
                </button>
                <button
                  size="sm"
                  @click="deleteServiceMap(row.item._id)"
                  class="btn text-danger btn-xs"
                >
                  <feather type="trash-2"></feather>
                </button>
              </template>
              <template #table-busy>
                <div class="col-md-12">
                  <h6 class="sub-title mb-0 text-center">Loading...</h6>
                  <div class="loader-box">
                    <div class="loader-30"></div>
                  </div>
                </div>
              </template>
            </b-table>
          </b-row>
          <b-col class="mt-3 p-0">
            <b-col md="12" class="mt-3 text-right p-0">
                <div class="pagination">
                  <a v-show="currentPage != 1" @click="changePage('prev')">« Back</a>
                  <a class="active">{{currentPage}}</a>
                  <a v-show="servicesMapData.length >= 1" @click="changePage('next')">Next »</a>
                </div>
              </b-col>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      v-model="service_map_model"
      id="service-mapping"
      size="lg"
      title="Service Mapping"
      class="theme-modal"
      no-close-on-backdrop
      hide-footer
      @hide="getServiceMap()"
    >
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-12">
            <b-card class="p-2">
              <div class="row">
                <label class="col-md-2 col-12">Service <span class="danger">*</span> </label>
                <b-form-select
                  class="col-md-8 col-12 custom-select"
                  v-model="input.sid"
                  :options="truncatedServiceList"
                  ></b-form-select>
                  <!-- :options="serviceList" -->
              </div>
              <div class="row mt-4">
                <label class="col-md-2 col-12">Reason <span class="danger">*</span></label>
                <b-form-select
                  class="col-md-8 col-12 custom-select"
                  v-model="input.reason"
                  :options="reasonOptions"
                ></b-form-select>
              </div>
              <div class="row mt-4">
                <label class="col-md-2 col-12">Value <span class="danger">*</span></label>
                <b-form-input
                  class="col-md-8 col-12"
                  v-model="input.svalue"
                  placeholder="value"
                ></b-form-input>
              </div>
              <div class="row mt-4 d-flex justify-content-center">
                <b-button
                  class="col-md-3 col-12"
                  variant="primary"
                  @click="Submit()"
                  :disabled="isBusy"
                  >{{ editServicemap ? "Edit" : "Submit" }}</b-button
                >
              </div>
            </b-card>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import ServiceInfoApi from "../../../services/ServiceInfo";
import config from "../../../services/globalLit";
import Multiselect from "vue-multiselect";

export default {
  name: "ServiceMapping",
  watch: {
    selectedReason: {
      handler() {
        this.filterData();
      },
      deep: true,
    },
    "filterServiceMap.svalue": {
      handler() {
        this.filterData();
      },
      deep: true,
    },
    "filterServiceMap.sid": {
      handler() {
        this.filterData();
      },
      deep: true,
    },
  },

  components: {
    Multiselect,
  },
  data() {
    return {
      // NEW CHANGES ENDS
      input: {
        sid: null,
        reason: null,
        svalue: "",
      },
      serviceList: [{ value: null, text: "Please select service" }],
      reasonOptions: [
        { value: null, text: "Please select reason" },
        { value: "1", text: "host" },
        { value: "2", text: "url" },
      ],
      services: [],
      servicesMapData: [],
      // providerList: [],
      selectedReason: null,
      filterServiceMap: {
        reason: 1,
        svalue: "",
        sid: "",
      },
      service_map_model: false,
      isBusy: false,
      serviceMapperFields: [
        { key: "sid", label: "Service", sortable: true },
        { key: "reason", label: "Reason", sortable: true },
        { key: "svalue", label: "Value", sortable: true },
        { key: "action", label: "Action" },
      ],
      editServicemap: false,
      totalRows: 0,
      totalPages: 0,
      filter: null,
      filterv: null,
      filterReason: null,
      currentPage: 1,
      perPage: 20,
      pageOptions: [5,10,20, 50, 100, 200],
      sortBy: "sid",
      sortDesc: true,
      sortDirection: "desc",
      providerList: [
        {
          _id: "1",
          name: "Provider A",
          host: "hostA.com",
          url: "https://hostA.com",
        },
        {
          _id: "2",
          name: "Provider B",
          host: "hostB.com",
          url: "https://hostB.com",
        },
      ],
      svalueData: [],
      serviceData: [],
      originalProviderList: [],
      originalSvalueData: [],
      originalServiceData: [],
      // filterServiceMap: {
      //   reason: null,
      // },
    };
  },

  computed: {
    truncatedServiceList() {
      return this.serviceList.map(option => ({
        value: option.value,
        text: option.text.length > 50 ? `${option.text.substring(0, 47)}...` : option.text
      }));
    },
  },

  mounted() {
    this.init();
  },
  methods: {
    filteProvider(data) {
      console.log(data);
    },
    async filterData() {
      console.log("this.filterServiceMap => ", this.filterServiceMap);

      let payload = {};

      if (this.selectedReason?.name) {
        payload.reason = this.selectedReason?.name === "host" ? 1 : 2;
      }

      if (this.filterServiceMap.svalue?.name) {
        payload.svalue = this.filterServiceMap.svalue?.name;
      }

      if (this.filterServiceMap.sid?._id) {
        payload.sid = this.filterServiceMap.sid?._id;
      }

      this.getServiceMap(payload);
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getService() {
      let response = await ServiceInfoApi.get();
      if (response.result) {
        this.services = response.data;
        this.serviceList = [{ value: null, text: "Please select service" }];
        this.services.map((t) => {
          this.serviceList.push({
            value: t._id,
            text: t.sp_name + " - " + t.title,
          });
        });
      }
    },
    async getServiceMap(payload) {
      this.input = { sid: null, reason: null, svalue: "" };

        payload.page = payload.page || this.currentPage;
        payload.limit = payload.limit || this.perPage;

      let response = await ServiceInfoApi.getServiceMapping(payload);
      if (response.result) {
        // this.providerList = response.data;
        // this.servicesMapData = [...this.providerList];


        // this.servicesMapData = response.data;
        this.servicesMapData = response.data.filter((element) => {
          return this.serviceList.some(service => service.value === element.sid);
        });


        this.totalRows = this.servicesMapData.length;
        this.totalPages = response.pagination.totalPages; // Total pages
        this.currentPage = response.pagination.currentPage; // Current page from API
        this.perPage = response.pagination.pageSize; // Page size from API

        console.log("this.servicesMapData", this.servicesMapData);


        this.providerList = [
          { _id: "1", name: "host" },
          { _id: "2", name: "url" },
        ];


        this.serviceData = response.data
          .map((element) => {
            const service = this.serviceList.find(
              (serviceItem) => serviceItem.value === element.sid
            );

            return {
              _id: element.sid || "",
              name: service ? service.text : ""
            };
          })
          .filter(item => item.name !== "" && item.name !== undefined); // Remove empty or undefined names

    console.log("serviceDataxyz =>", this.serviceData);
      }
    },

    changePage(direction) {
      if (direction === 'prev' && this.currentPage > 1) {
        this.currentPage--;
        this.filterData();
      } else if (direction === 'next') {
        this.currentPage++;
        this.filterData();
      }
    },

    handlePerPageChange(newPerPage) {
      this.perPage = newPerPage;
      this.currentPage = 1;
      this.filterData();
    },

    





    async getMapperServiceData(payload) {
        this.input = { sid: null, reason: null, svalue: "" };

        payload.page = this.currentPage;
      payload.limit = this.perPage;
        
        let response = await ServiceInfoApi.getServiceMapping(payload);
        if (response.result) {
          this.servicesMapData = response.data;
          this.totalRows = this.servicesMapData.length;

          console.log("this.servicesMapData2", this.servicesMapData);

          const reasonData = response.data.map((element) => {
            return {
              _id: element._id || "", // Default to empty string if _id is undefined
              name: element.reason === 1 ? "host" : element.reason === 2 ? "url" : "" // Handle reason more explicitly
            };
          });

          const svData = response.data.map((element) => {
            return {
              _id: element._id || "",
              name: element.svalue || "" // Default to empty string if svalue is undefined
            };
          });


          const sirData = response.data
              .map((element) => {
                // Find the matching service name
                const service = this.serviceList.find(
                  (serviceItem) => serviceItem.value === element.sid
                );

                return {
                  _id: element.sid || "",
                  name: service ? service.text : "" // Default to empty if not found
                };
              })
              .filter(item => item.name !== "" && item.name !== undefined); // Remove empty service names

              console.log("sirData => ", sirData);

          this.providerList = [
            { _id: "1", name: "host" },
            { _id: "2", name: "url" }
          ];

          this.originalProviderList = [...this.providerList];
          this.originalSvalueData = [...svData];
          this.originalServiceData = [...sirData];

          console.log("this.originalServiceData => ", this.originalServiceData);
          console.log("this.originalProviderList => ", this.originalProviderList);
          console.log("this.originalProviderList => ", this.originalSvalueData);

          // Only keep valid service names
          this.svalueData = svData.filter(item => item.name !== "");
          this.serviceData = sirData.filter(item => item.name !== "");
        }
    },
    



    async Submit() {
      if (this.validate()) {
        this.isBusy = true;
        var response;
        if (this.editServicemap) {
          var payload = {
            smid: this.input._id,
            changeBody: {
              sid: this.input.sid,
              reason: this.input.reason,
              svalue: this.input.svalue,
            },
          };
          response = await ServiceInfoApi.editServiceMapping(payload);
          this.editServicemap = false;
        } else {
          response = await ServiceInfoApi.addServiceMapping(this.input);
        }
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
          onComplete: () => {
            this.service_map_model = false;
            this.getServiceMap();
            this.isBusy = false;
          },
        });
      }
    },
    validate() {
      const showToast = (message) => {
        this.$toasted.show(message, {
          theme: "outline",
          position: "bottom-center",
          type: "error",
          duration: 2000,
        });
      };
      if (this.input.sid == null || this.input.sid == "") {
        showToast("Please select service");
        return false;
      }
      if (this.input.reason == null || this.input.reason == "") {
        showToast("Please select reason");
        return false;
      }
      if (this.input.svalue == null || this.input.svalue == "") {
        showToast("Please enter value");
        return false;
      }
      if (
        this.input.reason == "1" &&
        config.validateHost(this.input.svalue) == false
      ) {
        showToast("Please enter valid host value");
        return false;
      }

      if (this.input.reason == "2") {
        //validate given value is keyword that not include any comma or space or dot
        if (config.validateKeyword(this.input.svalue) == false) {
          showToast("Please do not use host value. Use only keyword");
          return false;
        }
      }
      return true;
    },
    OpenServiceMap() {
      this.input = {
        sid: null,
        reason: null,
        svalue: "",
      };
      this.editServicemap = false;
      this.service_map_model = true;
    },
    async deleteServiceMap(id) {
      if (confirm("Are you sure you want to delete this service mapping?")) {
        let payload = { smid: id };
        let response = await ServiceInfoApi.deleteServiceMapping(payload);
        let variant = response.result ? "success" : "error";
        this.$toasted.show(response.message, {
          theme: "outline",
          position: "bottom-center",
          type: variant,
          duration: 2000,
        });
        this.getServiceMap();
      }
    },
    editServiceMap(data) {
      this.editServicemap = true;
      this.input = data;
      this.service_map_model = true;
    },

    async init() {
      // Reset fields
      this.selectedReason = null;
      this.filterServiceMap = {
        reason: null,
        svalue: "",
        sid: "",
      };



      this.currentPage = 1;

      let payload = {};
      await this.getService();

      this.getMapperServiceData(payload={});
      this.getServiceMap(payload);
    },
  },
};
</script>
<style scoped>
.showBreadcrumbs {
  display: none !important;
}

.danger{
  color: red;
}

.custom-select {
  width: 10%;
  max-width: 100%; /* Ensures it stays within its column */
  overflow: hidden; /* Hides overflowing content */
  text-overflow: ellipsis; /* Adds ellipsis for long text */
  white-space: nowrap; /* Prevents text from wrapping */
}

@media screen and (max-width: 767px) {
  .custom-font-18,
  .custom-font-24 {
    font-size: 16px !important;
  }

  .custom-select {
    width: 100%; /* Full width within col-md-8 */
    max-width: 400px; /* Set a reasonable max-width for desktop */
  }
}

@media screen and (max-width: 991px) {
  .showBreadcrumbs {
    display: block !important;
  }
}
</style>
